.task-info {
  position: absolute;
  display: block;
  background: #ffffff;
  padding: 0 24px;
  left: calc(100% + 12px);
  top: 50%;
  transform: translateY(-50%);
  width: 422px;
  text-align: left;
  border-radius: 5px;
  box-shadow: 0 0 16px rgba(96, 96, 96, 0.25);
  max-height: 0;
  overflow: hidden;
  cursor: default;
  transform-origin: left center;
  z-index: 999;

  & > * {
    opacity: 0;
    transform: scale(.95);
    transition: 0.3s ease;
  }

  &_active {
    padding: 64px 24px 0;
    max-height: max-content;
    transition: 0.3s ease;
  }

  &_active > * {
    opacity: 1;
    transition: 0.3s ease .3s;
    transform: none;
  }

  &__title {
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    color: #333333;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__time {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;
    column-gap: 5px;

    &-block {
      display: flex;
      flex-direction: column;
      row-gap: 5px;

      span {
        font-size: 14px;
        line-height: 18px;
        color: #333333;
      }
    }
    &-center {
      align-items: center;
    }
  }

  &__text {
    height: max-content;
    color: #A5A7A8;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .new-button {
    margin-left: auto;
    margin-top: 40px;
    margin-bottom: 24px;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    margin-top: 40px;

    .new-button {
      margin: 0;
    }
  }

  &__banner {
    width: calc(100% + 50px);
    margin: 0 -25px;
    background: #FFF3F3;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #FE8282;
    text-align: center;
    padding: 8px 0;
  }

  &__close,
  &__remove,
  &__edit {
    padding: 8px;
    position: absolute;
    display: flex;
  }

  &__close {
    top: 16px;
    right: 16px;
  }

  &__remove {
    top: 16px;
    right: 56px;
  }

  &__edit {
    top: 16px;
    right: 96px;
    cursor: pointer;
  }
}
